<script setup lang="ts">
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { breakpointsTailwind } from '@vueuse/core'
import { getSBLink } from './helpers'
import type { MultilinkStoryblok, SbHeroSectionStoryblok } from '~/types/storyblok'

const props = defineProps<{ blok: SbHeroSectionStoryblok }>()

const links = computed(() => {
  return {
    primary: getSBLink(props.blok.primaryButtonLink as MultilinkStoryblok),
    text: getSBLink(props.blok.textButtonLink as MultilinkStoryblok),
  }
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isImageShown = computed(() => {
  if (!props.blok.desktopImage.filename) {
    return false
  }
  return process.server ? true : breakpoints.greaterOrEqual('lg').value
})
</script>

<template>
  <section v-editable="blok" class="border-b border-grey-stroke sm:pt-11 lg:pt-0">
    <div class="container">
      <div class="mx-auto mb-9 flex max-w-[600px] grid-cols-12 flex-col items-center gap-x-5 gap-y-6 lg:mb-0 lg:grid lg:min-h-96 lg:max-w-full xl:min-h-[32rem]">
        <div class="col-span-6 pt-3 lg:py-10">
          <CfgTypography
            class="break-words font-highlighted"
            :size="CfgTypographySizes.h1"
            tag-name="h1"
          >
            {{ blok.title }}
          </CfgTypography>

          <CfgTypography
            v-if="blok.description"
            class="mt-3 break-words !font-normal lg:mt-5"
            :size="CfgTypographySizes.subtitle"
            tag-name="p"
          >
            {{ blok.description }}
          </CfgTypography>

          <div class="mt-7 flex flex-col items-center gap-2 lg:mt-8 lg:flex-row lg:flex-wrap">
            <NuxtLink
              v-if="blok.primaryButtonLink && blok.primaryButtonText"
              class="w-full lg:w-fit"
              :to="links.primary"
            >
              <CfgButton
                as="span"
                class="w-full"
                variant="primary"
              >
                {{ blok.primaryButtonText }}
              </CfgButton>
            </NuxtLink>

            <NuxtLink
              v-if="blok.textButtonLink && blok.textButtonText"
              class="w-full lg:w-fit"
              :to="links.text"
            >
              <CfgButton
                as="span"
                class="!m-0 w-full"
                text
                variant="primary"
              >
                {{ blok.textButtonText }}
              </CfgButton>
            </NuxtLink>
          </div>
        </div>

        <div class="relative col-span-6 size-full">
          <picture v-if="isImageShown" class="left-0 top-0 hidden size-full lg:absolute lg:block">
            <source media="(min-width: 1024px)" :srcset="blok.desktopImage.filename">

            <img
              :alt="blok.desktopImage.alt"
              class="mt-auto h-auto w-full object-contain object-center-bottom lg:h-full"
              height="512"
              preload
              :src="blok.desktopImage.filename"
              width="736"
            >
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>
